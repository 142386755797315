.overview {
    background-color: $brand-sec;

    li {
        font-weight: 500;
        line-height: 27px;
        list-style: none;
        margin: 2rem 0 2rem -40px;
        padding: 5px 0;
        align-content: center;
        flex-wrap: wrap;
        
        &.checkmark {
            background: url('/images/check.svg') no-repeat left center;
            background-size: 42px;
            padding-left: 60px;
        }

        &.doc {
            background: url('/images/icon_doc.svg') no-repeat left center;
            background-size: 42px;
            padding-left: 60px;
        }
        
        &.document {
            background: url('/images/icon_document.svg') no-repeat left center;
            background-size: 42px;
            padding-left: 60px;
        }
        
        &.leitlinien {
            background: url('/images/icon_leitlinien.svg') no-repeat left center;
            background-size: 42px;
            padding-left: 60px;
        }

        &.pzn {
            background: url('/images/icon_pzn.svg') no-repeat left center;
            background-size: 42px;
            padding-left: 60px;
        }

        &.cal {
            background: url('/images/icon_cal.svg') no-repeat left center;
            background-size: 42px;
            padding-left: 60px;
        }
    }
}
