.section-teleclinic {
    background-color: #fdefbd;

    a {
        background-color: #342b07;
        color: #fff;
    }

    p {
        color: #666563;
    }
}