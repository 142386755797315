.navbar {
    .container {
        padding-bottom: 5px;
    }

    .nav-link-span {
        padding-left: $navbar-nav-link-padding-x;
        padding-right: $navbar-nav-link-padding-x;
    }

    .navbar-nav .nav-link {
        color: #fff !important;
    }

    .navbar-nav .nav-link.activeItem {
        color: #352b06 !important;
    }

    .navbar-nav .nav-link:hover {
        color: rgba(255, 255, 255, 0.8) !important;
    }
}

.nav-button {
    .nav-link {
        background: $brand-custom-sec;
        border-radius: 25px;
        .nav-link-span {
            color: #3b3b3b;
        }
    }
}

.bob-navbar .nav-item a.activeItem {
    color: #352b06 !important;
}

#bofu-landing {
    .navbar .nav-item {
        img {
            width: 45px;
            height: 45px;
            margin: 0;
        }

        #img-bund {
            width: 60px;
        }
    }
}