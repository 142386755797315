section.cite {
    .container {
        background: $brand-app;

        .row {
            padding: 2rem 2rem;
        }

        hr.citeLine {
            width: 300px;
            border-color: #fff;
            margin: 3rem auto;
        }

        p {
            color: #fff;

            &.citeText {
                font-weight: 500;
                color: #fff;
                font-size: 26px;
            }

            span {
                color: #fff;
                font-weight: bold;
            }
        }

        span {
            &.citePerson {
                color: $brand-sec;
                font-weight: 500;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    section.cite {
        .container {    
            .row {
                padding: 5rem 8rem;
            }

            p {
                &.citeText {
                    font-size: 34px;
                }
            }
        }
    }
}