.contact, .page_4 .contact {

    padding-bottom: 30px;

    h3 {
        font-size: 40px;
    }

    .contact-cards {
        padding-bottom: 0;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card-left, .card-right {
        a {
            color: $brand-heading-dark;
            white-space: nowrap;
            font-weight: 700;
        }

        .bob-style {
            color: #fff;
            background-color: #342b07;
        }
        
        span {
            color: $brand-app;
        }
    }

    .card-left {
        background-color: $card-left-bg-page1;
        color: #fff;

        h4, a {
            color: #fff;
        }
    }

    .card-top-wrapper {
        display: flex;
        margin-bottom: 30px;

        h4 {
            padding-top: 0px;
            align-self: center;
            margin-bottom: 0px;
        }

        .card-icon {
            width: 80px;
            height: auto;
            margin-right: 20px;
        }
    }
    
}

.contact-cards {
    .card {
        h3.app-color {
            color: #342b07;
        }
        a {
            &:hover {
                .btn-img-bg {
                    background-color: #342b07;
                }
            }
        }

        .btn-img-bg {
            background-color: #342b07;
        }
    }
}

.page_4 .contact h3 {
    display: none;
}

.page_5 .contact-cards {
    .card-left {
        h4, a {
            color: #fff !important;
        }
    }
}

.page_9 {
    
    .container.beratung {
        margin: 100px auto;
    }

    .bob-style {
        color: #fff;
        background-color: #342b07;
    }
    .bob-close {
        display: none;
    }
}